import { createSlice } from '@reduxjs/toolkit'

export const checkoutInfoSlice = createSlice({
    name: 'checkout',
    initialState: null,
    reducers: {
        addInfo: (state, action) => {
            // Redux Toolkit allows us to write "mutating" logic in reducers. It
            // doesn't actually mutate the state because it uses the Immer library,
            // which detects changes to a "draft state" and produces a brand new
            // immutable state based off those changes
            return action.payload;
        },
        removeComplete: (state) => {
            if(state?.isComplete){
                state.isComplete = null;
            }
            else{
                return [];
            }
        },
        removeInfo: (state) => {
            return [];
        },

    },
});

// Action creators are generated for each case reducer function
export const { addInfo, removeInfo, removeComplete } = checkoutInfoSlice.actions

export const checkoutInfoReducer = checkoutInfoSlice
