import { createTheme } from '@mui/material/styles';
import { blue, red } from '@mui/material/colors';

// Create a theme instance.
const theme = createTheme({
	typography: {
		fontFamily: "Didact Gothic"
	},
	palette: {
		primary: {
			main: '#2563eb',
			contrastText: '#fff',
		},
		secondary: {
			main: '#dc2626',
		},
		neutral: {
			main: '#ff000a',
			contrastText: '#fff',
		},
		black:{
			main: '#222222',
			contrastText: '#f7e332',
		},
		label:{
			main: '#222222',
			sub: '#ff000a',
		},
		error: {
			main: red.A400,
		},
		link:{
			main: blue[600],
			secondary: blue[400]
		}
	},
});

export default theme;
