import { configureStore } from '@reduxjs/toolkit';
import { cartReducer }  from './cart.slice';
import { checkoutInfoReducer }  from './checkout.slice';
import { gulongphReducer }  from './gulongph.slice';
import { loadState, loadCheckoutInfoState, loadGulongState } from './localStorage';

let cart = loadState();
let checkout = loadCheckoutInfoState();
let gulongph = loadGulongState();
const preloadedState = {...cart, checkout, gulongph};

const store = configureStore({
      reducer: {
        cart: cartReducer.reducer,
        checkout: checkoutInfoReducer.reducer,
        gulongph: gulongphReducer.reducer,
    },
    preloadedState
});

store.subscribe(() => {
    const state = store.getState();
    const cart = store.getState().cart;
    const checkout = store.getState().checkout;
    const gulong = store.getState().gulongph;
    localStorage.setItem('cartState', JSON.stringify({ cart: cart }));
    localStorage.setItem('checkoutState', JSON.stringify(checkout));
    localStorage.setItem('gulongph', JSON.stringify(gulong));
});

export default store;
