export const loadState = () => {
	try {
		const serializedState = localStorage.getItem("cartState");
		if (serializedState === null) {
			return undefined;
		}
		return JSON.parse(serializedState);
	}
	catch (err) {
		return undefined;
	}
};
export const loadCheckoutInfoState = () => {
	try {
		const serializedState = localStorage.getItem("checkoutState");
		if (serializedState === null) {
			return undefined;
		}
		else{
			let dt = JSON.parse(serializedState);
			if(dt.expiration){
				const now = new Date();
				const currentExpiration = new Date(dt.expiration);
				if(now.getTime() > currentExpiration.getTime()){
					return undefined;
				}
			}
		}
		return JSON.parse(serializedState);
	}
	catch (err) {
		return undefined;
	}
};

export const loadGulongState = () => {
	try {
		const serializedState = localStorage.getItem("gulongph");
		if (serializedState === null) {
			return undefined;
		}
		return JSON.parse(serializedState);
	}
	catch (err) {
		return undefined;
	}
};